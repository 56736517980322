export class VacationFlowDat {
  recordId: number;
  requestDate?: string | null;
  employeeId?: number | null;
  affiliationId?: number | null;
  shiftId?: string | null;
  substituteShiftId?: string | null;
  substituteDate?: string | null;
  vacationKbn?: number | null;
  resonKbn?: string | null;
  resonText?: string | null;
  remarks?: string | null;
  latestApprovalLevel?: number | null;
  latestApprovalFlag?: string | null;
  latestApprovalDate?: number | null;
  checkApprovalDate?: string | null;
  checkApprovalManager?: string | null;

  constructor(props: VacationFlowDat) {
    this.recordId = props.recordId;
    this.requestDate = props.requestDate;
    this.employeeId = props.employeeId;
    this.affiliationId = props.affiliationId;
    this.shiftId = props.shiftId;
    this.substituteShiftId = props.substituteShiftId;
    this.substituteDate = props.substituteDate;
    this.vacationKbn = props.vacationKbn;
    this.resonKbn = props.resonKbn;
    this.resonText = props.resonText;
    this.remarks = props.remarks;
    this.latestApprovalLevel = props.latestApprovalLevel;
    this.latestApprovalFlag = props.latestApprovalFlag;
    this.latestApprovalDate = props.latestApprovalDate;
    this.checkApprovalDate = props.checkApprovalDate;
    this.checkApprovalManager = props.checkApprovalManager;
  }
}
